import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout/layout.js';
import styles from '../styles/menu.module.css';
import { graphql } from 'gatsby';
import { isRedirect } from '@reach/router';

const Menu = ({ data }) => {
  const sections = [
    { title: 'Starters', subheader: '' },
    { title: 'Sushi Appetizers', subheader: '' },
    { title: 'Soup and Salad'},
    { title: 'Wings', subheader: 'Traditional Half Dozen $8 | Dozen $14 -- Boneless Half Pound $8 | 1 Pound $13 Spice Range 0-5'},
    { title: 'Asian Dishes', subheader: ''},
    //{ title: 'Drafthouse Combo', subheader: 'Each combo comes with your choice of miso soup or ginger salad'},
    { title: 'Sandwiches and Entrees', subheader: 'All sandwiches and wraps are served with chips! Substitute fries for $1. Substitute tots or onion rings for $2. Cheese choices: American, Cheddar, Provolone, Pepper Jack, Beer Cheese'},
    { title: 'Sides', subheader: '' }
    


  ];

  return (
    <Layout>
      <Helmet>
        <title>River City Drafthouse</title>
        <meta
          name='River City Drafthouse Food'
          content='River City Drafthouse Food Menu'
        />
      </Helmet>
      <div className={styles.container}>
        {sections.map(section => {
          return (
            <section className={styles.menuSection}>
              <header className={styles.sectionHeader}>
                <h1 className={styles.sectionTitle}>{section.title}</h1>
                <h3 className={styles.sectionSubheader}>{section.subheader}</h3>
              </header>
              {data.allMarkdownRemark.edges.map(element => {
                const {
                  title,
                  comboNumber,
                  price,
                  smallPrice,
                  description,
                  category,
                  item1,
                  item2,
                  item3,
                  item4,
                  item5,
                  item6
                } = element.node.frontmatter;
                if (category === section.title && section.title !== 'Drafthouse Combo') {
                  return (
                    <div className={styles.menuItem}>
                      <span className={styles.itemHeader}>
                        <h1 className={styles.itemName}>{title}</h1>
                        {/*smallPrice ? <p className={styles.price}>M: {smallPrice}</p> : ""*/}
                        {/*<p className={styles.price}>L: {price}</p>*/}
                        <p className={styles.price}>{price}</p>
                      </span>
                      {description ? (
                        <p className={styles.description}>{description}</p>
                      ) : (
                        ''
                      )}
                    </div>
                  );
                } else if (
                  category === section.title &&
                  section.title === 'Drafthouse Combo'
                ) {
                  return (
                    <div className={styles.comboItem}>
                      <span className={styles.itemHeader}>
                        <h1 className={styles.itemName}>
                          {/*comboNumber*/} {title}
                        </h1>
                        {/* {smallPrice ? (
                          <p className={styles.price}>L: {smallPrice}</p>
                        ) : (
                          ''
                        )} */}
                        {/* <p className={styles.price}>D: {price}</p> */}
                      </span>
                      {item1 ? (
                        <p className={styles.comboDescription}>{item1}</p>
                      ) : (
                        ''
                      )}
                      {item2 ? (
                        <p className={styles.comboDescription}>{item2}</p>
                      ) : (
                        ''
                      )}
                      {item3 ? (
                        <p className={styles.comboDescription}>{item3}</p>
                      ) : (
                        ''
                      )}
                      {item4 ? (
                        <p className={styles.comboDescription}>{item4}</p>
                      ) : (
                        ''
                      )}
                      {item5 ? (
                        <p className={styles.comboDescription}>{item5}</p>
                      ) : (
                        ''
                      )}
                      {item6 ? (
                        <p className={styles.comboDescription}>{item6}</p>
                      ) : (
                        ''
                      )}
                    </div>
                  );
                }
              })}
            </section>
          );
        })}
      </div>
      <p>
      <br></br>
      </p>
      <p>
        <br></br>
      </p>
      <p>
        <br>
          
        </br>
      </p>
    </Layout>
  );
};

export const query = graphql`
  query menuQuery {
    allMarkdownRemark(sort: { fields: frontmatter___comboNumber, order: ASC }) {
      edges {
        node {
          frontmatter {
            title
            path
            category
            comboNumber
            price
            smallPrice
            description
            item1
            item2
            item3
            item4
            item5
            item6
          }
        }
      }
    }
  }
`;
export default Menu;
